import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  Alert,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Button, Col, ErrorText, Icon } from "../../components/Component";
import { useEffect } from "react";
import moment from "moment";
import { markAsComplete, uploadCertificateFile } from "./StaffData";
import { FrequencyOptions } from "../dashboard/Roles/RolesData";
import { AppText, dateToShowFormate, showErrorToast } from "../../utils";
import { bytesToMegaBytes } from "../../utils/Utils";
import { iconsType } from "../Resources/ResourceData";

export const MarkAsComplete = ({
  visible,
  onClosed,
  staffId,
  staffName,
  data,
  onChange,
}) => {
  const [completedAt, setCompletedAt] = useState(new Date());
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const handleFormSubmit = () => {
    // if isManualCertificate = true, attachement is requires field
    if (data?.isManualCertificate && !selectedFile) {
      setIsError(true);
      return;
    }

    if (completedAt !== undefined) {
      const completedDate = completedAt
        ? moment(completedAt).format("YYYY-MM-DD")
        : undefined;

      let params = {
        userId: staffId,
        courseId: data?.id,
        completedDate: completedDate,
        fileId: selectedFile?.id,
      };
      markAsTrainingComplete(params);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (visible) {
    } else {
      setIsLoading(false);
      setIsError(false);
      setSelectedFile();
    }
  }, [visible]);

  function markAsTrainingComplete(params) {
    setIsLoading(true);
    markAsComplete(params, (data, error) => {
      if (error === null) {
        onChange();
        onClosed();
      } else {
        setError(
          error.message?.length > 0 ? error.message : AppText.connectError
        );
      }
      setIsLoading(false);
    });
  }

  function getFrequency(text) {
    if (text) {
      let list = FrequencyOptions.filter((item) => item.value === text);
      return list.length === 1 ? list[0] : null;
    }
    return null;
  }

  const handleFileSelect = (file) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;
      setSelectedFile(fileDetails);
      // for API
      uploadFile(fileDetails);
    }
  };

  const uploadFile = (file) => {
    uploadCertificateFile(file, "MANUAL_COURSE_CERTIFICATE", (data, error) => {
      if (error === null) {
        let object = {};
        object.id = data.id;
        object.isLoading = false;
        object.name = file.name;
        object.type = file.type;
        object.size = file.size;
        object.fileUrl = data.fileUrl;
        setSelectedFile(object);
      } else {
        setSelectedFile();
        showErrorToast(
          error.message.length > 0 ? error.message : AppText.connectError
        );
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={() => onClosed()}
        className="modal-md mark-as-complete"
      >
        <ModalHeader className="bg-purple text-white" toggle={onClosed}>
          <p className="title">Mark as complete</p>
        </ModalHeader>
        {error && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {error}{" "}
            </Alert>
          </div>
        )}
        <ModalBody>
          <div className="p-2">
            <Form className="row gy-4">
              <Col>
                <div className="form-group">
                  <label className="form-label">Completed Date</label>
                  <div className="position-relative">
                    {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                    <DatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      selected={completedAt}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={(date) => {
                        setCompletedAt(date);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col className={"d-flex justify-content-between mb-1"}>
                <label className="text-dark">Course</label>
                <label className="text-secondory">{data?.name}</label>
              </Col>
              <Col className={"d-flex justify-content-between my-1"}>
                <label className="text-dark">Learner</label>
                <label className="text-secondory">{staffName}</label>
              </Col>
              <Col className={"d-flex justify-content-between my-1"}>
                <label className="text-dark">Renewal frequency</label>
                <label className="text-secondory">
                  {getFrequency(data?.frequency)?.label ?? ""}
                </label>
              </Col>
              <Col className={"d-flex justify-content-between my-1"}>
                <label className="text-dark">Due date</label>
                <label className="text-secondory">
                  {dateToShowFormate(data?.maxDueAt)}
                </label>
              </Col>
              {data?.isManualCertificate ? (
                <Col>
                  <div className="form-group mb-0 mt-n2">
                    <label htmlFor={"files"}>
                      <Icon name="clip" className="mb-1 text-primary" />
                      <span className="text-primary">
                        {" "}
                        Attach your certificate
                      </span>
                    </label>
                    <input
                      type="file"
                      name="files"
                      accept=".pdf,.doc,.docx"
                      className="form-control"
                      id={"files"}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let temp = Array.prototype.slice.call(e.target.files);
                        if (temp.length > 0) {
                          handleFileSelect(temp[0]);
                        }
                      }}
                    />
                    {!selectedFile && isError ? <ErrorText /> : null}
                  </div>

                  {selectedFile ? (
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {iconsType[selectedFile.type]
                          ? iconsType[selectedFile.type]
                          : iconsType["others"]}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title">{selectedFile.name}</span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {selectedFile.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span
                              onClick={() => {
                                setSelectedFile();
                              }}
                            >
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  ) : null}
                </Col>
              ) : null}
              <Col>
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      color="primary"
                      className="custom-solid-btn-sm"
                      size="md"
                      disabled={isLoading}
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleFormSubmit();
                      }}
                    >
                      {isLoading ? (
                        <Spinner size="sm" color="dark" />
                      ) : (
                        "Mark as complete"
                      )}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
