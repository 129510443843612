import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

export const BarChart = ({ data }) => {
  const dataUnit = data?.dataUnit ?? "N/A";
  console.log("YYYYYYYYYYY", data);

  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      width={180}
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItem) => {
                return `${tooltipItem.raw}${dataUnit}`;
              },
            },
          },
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
          },
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};
