const myTimeout = setTimeout(myStopFunction, 100);

function myStopFunction() {
  const para = document.createElement("div");
  para.classList.add("table-div");
  const table = document.querySelector("#course-chapter-details table");
  if (para && table) {
    const sElement = document
      .getElementById("course-chapter-details")
      .appendChild(para)
      .appendChild(table);
  }
}
