// src/redux/slices/certificateSlice.js
import { createSlice } from "@reduxjs/toolkit";

const certificateSlice = createSlice({
  name: "certificate",
  initialState: {
    pendingCount: 0,
  },
  reducers: {
    setIsPending: (state, action) => {
      state.pendingCount = action.payload;
    },
  },
});

export const { setIsPending } = certificateSlice.actions;

export default certificateSlice.reducer;
