import React, { useRef } from "react";
import { useState } from "react";
import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  UserAccountType,
  dateToShowFormate,
  dueDateColor,
  openLinkInNewTab,
  showErrorToast,
  showSuccessToast,
  toCapitalize,
} from "../../utils/Utils";
import { useHistory, useLocation } from "react-router";
import EditStaffTraining from "./EditStaffTraining";
import { useEffect } from "react";
import {
  fetchStaffDetail,
  getAssignedCourseList,
  getFTFTrainingList,
  markNotMandatory,
  removeStaff,
  resendInvitation,
  resetStaffTraining,
} from "./StaffData";
import moment from "moment";
import LoadingComponent from "../../layout/spinner/LoadingSpinner";
import { AppText, FTFBookingStatus } from "../../utils";
import { downloadCertificate } from "../dashboard/Certificate/CertificateData";
import { Storage } from "../../services/storage/storage";
import { MarkAsComplete } from "./MarkAsComplete";
import classNames from "classnames";
import Swal from "sweetalert2";
import AddCpd from "./AddCPD";
import CPDList from "./CPDList";
import ArchiveCertificateModal from "./ArchiveCertificateModal";
import { CertificatesList } from "../dashboard/Certificate/GeneratedCertificate";

const StaffTraining = () => {
  const [assignedCourse, setAssignedCourse] = useState();

  const [FTFList, setFTFList] = useState();
  const [staff, setStaff] = useState({});
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActivetab] = useState("1");
  const [cpdModal, setCpdModal] = useState(false);
  const cpdListRef = useRef();
  const certificatesListRef = useRef();

  const user = Storage.getLoggedInUser();
  const staffName =
    toCapitalize(staff?.firstName?.trim() ?? "") +
    " " +
    toCapitalize(staff?.lastName?.trim() ?? "");
  const location = useLocation();

  const history = useHistory();

  const onAddClick = (e) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/training-wizard`,
      state: { data: assignedCourse, user: staff },
    });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id !== undefined || null || " ") {
      loadStaff(id);
    } else {
      history.goBack();
      setIsInitScreen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadStaff(id) {
    let params = {
      userId: id,
    };
    fetchStaffDetail(id, (data, error) => {
      if (error === null) {
        let staffData = data.data;
        setStaff(staffData);
        loadAssignedCourses(id);
      } else {
        setIsInitScreen(false);
      }
    });
  }

  function loadAssignedCourses(userId) {
    let params = {
      userId: userId,
      isOptionalButCompleteInclude: true,
    };
    getAssignedCourseList(params, (data, error) => {
      if (error === null) {
        setAssignedCourse(data);
      }
      setIsInitScreen(false);
      loadFTF(params);
    });
  }

  function loadFTF(params) {
    getFTFTrainingList(params, (data, error) => {
      if (error === null) {
        setFTFList(data);
      }
      setIsInitScreen(false);
    });
  }

  function handleResendInvitation() {
    let params = {
      userId: staff?.id,
    };
    resendInvitation(params, (data, error) => {
      if (error === null) {
        showSuccessToast(`The invitation has been sent successfully!`);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  const onUpdateClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-staff`,
      state: {
        staff: staff,
      },
    });
  };

  const onRemoveClick = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${staffName ?? ""}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        removeStaff({ id: staff?.id }, (data, error) => {
          if (error === null) {
            showSuccessToast("Staff is removed successfully");
            history.goBack();
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  };

  function onDownloadAllClick(ev) {
    let params = {
      isMyCertificates: false,
      userId: staff?.id,
    };
    setIsLoading(true);
    downloadCertificate(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.downloadUrl, ev);
      } else {
        showErrorToast(
          error.message?.length > 0 ? error.message : AppText.connectError
        );
      }
      setIsLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Head title="add-new-training" />
      {isInitScreen === false && (
        <Content>
          <div className="wide-md mx-auto">
            <BlockHead size="sm">
              <div className="d-flex align-items-md-center justify-content-md-between flex-column flex-md-row">
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    <span>{staffName}</span>
                  </BlockTitle>
                </BlockHeadContent>
                {(user.accountType === UserAccountType.admin ||
                  user.accountType === UserAccountType.manager) && (
                  <BlockHeadContent
                    className={
                      "mt-3 mt-md-0 d-flex flex-column flex-md-row align-items-start align-items-md-center flex-wrap "
                    }
                  >
                    <a
                      href="#detail"
                      id="#detail"
                      color="primary"
                      className=" custom-outline-btn bg-white mb-2 mb-md-0"
                      onClick={onUpdateClick}
                    >
                      Edit Details
                      <span className="ni ni-edit-alt ms-1 fs-5"></span>
                    </a>
                    {staff?.lastLogin ? (
                      <></>
                    ) : (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ cursor: "pointer" }}
                          color="primary"
                          className="ms-0 ms-md-2 ps-2 custom-outline-btn bg-white mb-2 mb-md-0"
                          onClick={handleResendInvitation}
                        >
                          Resend invitation
                          <span className="ni ni-send ms-1 fs-5"></span>
                        </a>
                      </>
                    )}
                    <a
                      href="#detail"
                      id="#detail"
                      color="primary"
                      className="ms-0 ms-md-2 custom-outline-btn bg-white"
                      onClick={onRemoveClick}
                    >
                      Remove
                      <span className="ni ni-trash-alt ms-1 fs-5"></span>
                    </a>
                  </BlockHeadContent>
                )}
              </div>
            </BlockHead>
            <Block>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content ">
                    <div className="card-inner">
                      <Block>
                        <Row>
                          <Col
                            sm="6"
                            className="p-3 border-end border-none-asm pe-sm-36 "
                          >
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Role
                                </span>
                              </Col>
                              <Col className="col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.role?.name ?? ""}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm="6" className="p-3 ps-sm-36">
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Status
                                </span>
                              </Col>
                              <Col className=" col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.status === "ACTIVE"
                                    ? "Active"
                                    : "Paused"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            sm="6"
                            className="p-3 border-end border-none-asm pe-sm-36"
                          >
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Location
                                </span>
                              </Col>
                              <Col className=" col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.homeLocation?.name ?? ""}
                                </span>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm="6" className="p-3 ps-sm-36">
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Last login
                                </span>
                              </Col>
                              <Col className=" col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.lastLogin
                                    ? moment(staff?.lastLogin).format(
                                        "DD-MM-YYYY / hh:mm A"
                                      )
                                    : "Invitation sent"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            sm="6"
                            className="p-3 border-end border-none-asm pe-sm-36"
                          >
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Email
                                </span>
                              </Col>
                              <Col className=" col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.email ?? ""}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm="6" className="p-3 ps-sm-36">
                            <Row>
                              <Col className="col-4">
                                <span className="profile-ud-label text-secondary">
                                  Start date
                                </span>
                              </Col>
                              <Col className=" col-8 text-end">
                                <span className="profile-ud-value fw-bold">
                                  {staff?.startDate
                                    ? moment(staff?.startDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Block>
                    </div>
                  </div>
                </div>
              </Card>
            </Block>
            <div>
              <Nav
                tabs
                className="bg-white mt-5 px-2 pb-2 d-flex justify-content-md-between flex-column flex-md-row border-0"
              >
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={`${classNames({
                      active: activeTab === "1",
                    })} me-2`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("1");
                    }}
                  >
                    Trainings
                  </NavLink>
                  <NavLink
                    tag="a"
                    href="#tab"
                    // className={classNames({ active: activeTab === "2" })}
                    className={`${classNames({
                      active: activeTab === "2",
                    })} me-2`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("2");
                    }}
                  >
                    Face to Face
                  </NavLink>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={`${classNames({
                      active: activeTab === "3",
                    })} me-2`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("3");
                    }}
                  >
                    CPD Trainings
                  </NavLink>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classNames({ active: activeTab === "4" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setActivetab("4");
                    }}
                  >
                    Certificates
                  </NavLink>
                </NavItem>

                <NavItem>
                  {activeTab === "1" || activeTab === "4" ? (
                    <div className="text-start text-md-end flex-column flex-sm-row flex-fill mt-2 ">
                      {activeTab === "1" ? (
                        <Button
                          color="primary"
                          className="btn-text custom-solid-btn-sm mb-1 mb-sm-0 me-1"
                          onClick={onAddClick}
                        >
                          Add training
                        </Button>
                      ) : null}
                      <Button
                        color="primary"
                        className="btn-text  custom-solid-btn-sm mb-1 mb-sm-0"
                        onClick={onDownloadAllClick}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Download all certificates"
                        )}
                      </Button>
                    </div>
                  ) : activeTab === "3" ? (
                    <>
                      <div className="text-start text-md-end flex-column flex-sm-row flex-fill mt-2 ">
                        <Button
                          color="primary"
                          className="btn-text custom-solid-btn-sm mb-1 mb-sm-0 me-1"
                          onClick={() => setCpdModal(true)}
                        >
                          Add CPD Training
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <>
                  <CourseListView
                    staffId={staff?.id}
                    staffName={staffName}
                    assignedCourse={assignedCourse}
                    onReloadList={() => {
                      loadAssignedCourses(location.state.id);
                    }}
                  />
                </>
              </TabPane>
              <TabPane tabId="2">
                <FaceToFaceView
                  staffId={staff?.id}
                  staffName={staffName}
                  list={FTFList}
                />
              </TabPane>
              <TabPane tabId="3">
                <CPDList
                  staffId={staff?.id}
                  ref={cpdListRef}
                  isLearnerSide={false}
                />
              </TabPane>
              <TabPane tabId="4">
                <CertificatesList
                  ref={certificatesListRef}
                  staffId={staff?.id}
                  isLearnerSide={false}
                />
              </TabPane>
            </TabContent>
          </div>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
      <AddCpd
        visible={cpdModal}
        staffId={staff?.id}
        isAddTraining={true}
        onClosed={() => {
          setCpdModal(false);
        }}
        onSuccess={() => {
          cpdListRef.current.reloadCPDList();
        }}
      />
    </React.Fragment>
  );
};

export default StaffTraining;

const CourseListView = ({
  staffId,
  staffName,
  assignedCourse,
  onReloadList,
}) => {
  const history = useHistory();
  const [selectedCourse, setSelectedCourse] = useState({});

  const [modal, setModal] = useState({
    editTraining: false,
    markAsComplete: false,
  });

  const [archiveModal, setArchiveModal] = useState(false);

  function MarkAsNotMandatory(courseId, courseName) {
    let params = {
      userId: staffId,
      courseId: courseId,
    };
    markNotMandatory(params, (data, error) => {
      if (error === null) {
        showSuccessToast(
          `The course "${courseName}" has been removed successfully!`
        );
        onReloadList();
      }
    });
  }

  function resetTraining(courseId, courseName) {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to reset ${courseName} to ${staffName ?? ""}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Reset",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = { courseId: courseId, userId: staffId };
        resetStaffTraining(params, (data, error) => {
          if (error === null) {
            showSuccessToast("Training has been reset successfully");
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  }

  return (
    <React.Fragment>
      <Block>
        <DataTable className={"course-list-view"}>
          <DataTableBody>
            <DataTableHead>
              <DataTableRow className="pt-3">
                <span className="sub-text">
                  <strong>Training name</strong>
                </span>
              </DataTableRow>
              <DataTableRow className="pt-3">
                <span className="sub-text">
                  <strong>Completed</strong>
                </span>
              </DataTableRow>
              <DataTableRow className="pt-3">
                <span className="sub-text">
                  <strong>Due date</strong>
                </span>
              </DataTableRow>
              <DataTableRow className="text-end pt-3">
                <span className="sub-text">
                  <strong>Actions</strong>
                </span>
              </DataTableRow>
            </DataTableHead>
            {assignedCourse?.list?.length > 0
              ? assignedCourse?.list?.map((item, index) => {
                  return (
                    <DataTableItem key={index}>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">{item?.name ?? ""} </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">
                            {item?.completedAt
                              ? dateToShowFormate(item?.completedAt)
                              : "N/A"}
                          </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          {item?.frequency === "ONE_OFF" &&
                          item?.completedAt ? (
                            <></>
                          ) : (
                            item?.maxDueAt && (
                              <Badge
                                className="badge-dim text-dark fs-0_775rem py-1 px-2"
                                color={dueDateColor(
                                  item?.dueInStartAt,
                                  item?.maxDueAt
                                )}
                              >
                                <span>
                                  {item?.maxDueAt
                                    ? dateToShowFormate(item?.maxDueAt ?? "")
                                    : ""}
                                </span>
                              </Badge>
                            )
                          )}
                        </div>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={() => {
                                        setSelectedCourse(item);
                                        setModal({
                                          editTraining: true,
                                        });
                                      }}
                                    >
                                      <span style={{ cursor: "pointer" }}>
                                        Edit training
                                      </span>
                                    </DropdownItem>
                                  </li>
                                  <>
                                    {item.certificateUrl &&
                                      item.completedAt && (
                                        <li
                                          onClick={(ev) => {
                                            openLinkInNewTab(
                                              item.certificateUrl,
                                              ev
                                            );
                                          }}
                                        >
                                          <DropdownItem tag="a">
                                            <span
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Download certificate
                                            </span>
                                          </DropdownItem>
                                        </li>
                                      )}
                                  </>
                                  <>
                                    {item.certificateUrl &&
                                      item.completedAt && (
                                        <li
                                          onClick={(ev) => {
                                            setSelectedCourse(item);
                                            setArchiveModal(true);
                                          }}
                                        >
                                          <DropdownItem tag="a">
                                            <span
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Archive certificate
                                            </span>
                                          </DropdownItem>
                                        </li>
                                      )}
                                  </>
                                  <>
                                    {item?.isEvaluationFormFilled === true && (
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={() => {
                                            history.push({
                                              pathname: `${process.env.PUBLIC_URL}/evalution-form`,
                                              state: {
                                                courseCompletedId:
                                                  item?.courseCompletedId,
                                                isEvaluationFormView: true,
                                              },
                                            });
                                          }}
                                        >
                                          <span
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            View Evaluation Form
                                          </span>
                                        </DropdownItem>
                                      </li>
                                    )}
                                  </>
                                  {/* {!item.completedAt && ( */}
                                  {!item.certificateUrl && (
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={() => {
                                          setSelectedCourse(item);
                                          setModal({
                                            markAsComplete: true,
                                          });
                                        }}
                                      >
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Mark as complete
                                        </span>
                                      </DropdownItem>
                                    </li>
                                  )}

                                  {/* )} */}
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={() =>
                                        MarkAsNotMandatory(item.id, item.name)
                                      }
                                    >
                                      <span style={{ cursor: "pointer" }}>
                                        Mark as non-mandatory
                                      </span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        resetTraining(item.id, item.name);
                                      }}
                                    >
                                      <span style={{ cursor: "pointer" }}>
                                        Reset Training
                                      </span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </DataTableBody>
        </DataTable>
      </Block>

      <EditStaffTraining
        dataInfo={selectedCourse}
        staffUser={staffId}
        visible={modal.editTraining}
        onClosed={() => {
          setModal({ editTraining: false });
          setSelectedCourse();
        }}
        onChange={() => onReloadList()}
      />
      <MarkAsComplete
        data={selectedCourse}
        staffId={staffId}
        staffName={staffName}
        visible={modal.markAsComplete}
        onClosed={() => {
          setModal({ markAsComplete: false });
          setSelectedCourse();
        }}
        onChange={() => onReloadList()}
      />
      <ArchiveCertificateModal
        visible={archiveModal}
        item={selectedCourse}
        staffId={staffId}
        onClosed={() => setArchiveModal(false)}
      />
    </React.Fragment>
  );
};

const FaceToFaceView = ({ staffId, staffName, list }) => {
  const history = useHistory();

  function showFTFDetail(info) {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/face-to-face-detail`,
      state: { trainingData: info },
    });
  }

  return (
    <React.Fragment>
      <Block>
        <DataTable className={""}>
          <DataTableBody>
            {list?.length > 0 ? (
              <>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">
                      <strong>Title</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">
                      <strong>Location</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      <strong>Date / Time</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text ">
                      <strong>Seats</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-end">
                    <span className="sub-text"></span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}

                {list.map((item, index) => {
                  return (
                    <DataTableItem
                      key={index}
                      className={"cursor"}
                      onClick={() => showFTFDetail(item)}
                    >
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">
                            {toCapitalize(item?.title ?? "")}
                          </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <div className="user-info">
                          <span className="tb-lead">
                            {item.isZoom ? "Zoom" : item?.location?.name ?? ""}{" "}
                          </span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <span>
                          {moment(item.dateTime).format("DD-MM-YYYY / hh:mm A")}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <div className="project-progress  ">
                          <div className="project-progress-percent">
                            {item?.totalBooking ?? 0} / {item?.totalSeats ?? 1}
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        {item?.myBooking?.status ===
                        FTFBookingStatus.applied ? (
                          <div className="project-progress py-1 text-end">
                            <span className="badge badge-dim bg-info text-dark fs-0_775rem py-1 px-2">
                              Booked
                            </span>
                          </div>
                        ) : item?.myBooking?.status ===
                          FTFBookingStatus.absent ? (
                          <div className="project-progress py-1 text-end text-dark fw-normal ">
                            <span className="badge badge-dim bg-danger text-dark fs-0_775rem py-1 px-2">
                              Absent
                            </span>
                          </div>
                        ) : item?.myBooking?.status ===
                          FTFBookingStatus.attended ? (
                          <div className="project-progress text-end">
                            <span className="badge badge-dim bg-success text-dark fs-0_775rem py-1 px-2">
                              Attended
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </DataTableRow>
                    </DataTableItem>
                  );
                })}
              </>
            ) : (
              <div className="m-3 text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </DataTableBody>
        </DataTable>
      </Block>
    </React.Fragment>
  );
};
