import React, { useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

import TrainingSlider from "./RequireTraining";
import { getCourseAssignedList, getOtherCourseList } from "./DueTrainingData";
import { useState } from "react";
import { useHistory } from "react-router";
import { Storage } from "../../../services/storage/storage";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { isPendingCertificateAPI } from "../Certificate/CertificateData";
import { useDispatch } from "react-redux";
import { UserAccountType } from "../../../utils";
import { setIsPending } from "../../../redux/slices/certificateSlice";

const ClientDashboard = () => {
  const [assignedCourse, setAssignedCourse] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [otherCourses, setOtherCourses] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  let user = Storage.getLoggedInUser();

  useEffect(() => {
    fetchCourses(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    loadIsPendingCertificateFlag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCourses = (search) => {
    getCourseAssignedList(searchText, true, (data, error) => {
      if (error === null) {
        setAssignedCourse(data.list);
      }
      getCourseAssignedList(searchText, false, (data, error) => {
        if (error === null) {
          setOtherCourses(data.list);
        }
      });
      setIsInitScreen(false);
    });
  };

  function loadIsPendingCertificateFlag() {
    let params = {
      isLearner: user?.accountType === UserAccountType.staff ? true : false,
    };
    isPendingCertificateAPI(params, (data, error) => {
      if (error === null) {
        dispatch(setIsPending(data?.totalPending));
      } else {
        console.error("Error fetching pending certificate flag", error);
      }
    });
  }

  const onViewAllClick = (isMandatory, assignedCourse, title) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/view-all-course`,
      state: { isMandatory: isMandatory, data: assignedCourse, title: title },
    });
  };

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <div className="wide-lg mx-auto">
          <div className="mb-3">
            <input
              className="form-control"
              type="text"
              name="search"
              value={searchText}
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          {assignedCourse.length > 0 ? (
            <TrainingSlider
              title={"Mandatory training"}
              list={assignedCourse ?? []}
              isMandatory={true}
              onViewAllClick={() =>
                onViewAllClick(true, assignedCourse, "Mandatory Training")
              }
            />
          ) : (
            <p className="p-2 text-center">
              You do not have any pending training.
            </p>
          )}
          {otherCourses.length > 0 ? (
            <TrainingSlider
              title={"Optional training"}
              list={otherCourses}
              isMandatory={false}
              onViewAllClick={() =>
                onViewAllClick(false, otherCourses, "Optional Training")
              }
            />
          ) : null}
        </div>
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default ClientDashboard;
