import moment from "moment";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import {
  dateToShowFormate,
  removeTimeFromServerDateString,
} from "../../../utils";

export const isCourseCompleted = (status, isApprovalCertStatus, startDueIn) => {
  if (
    status === "COMPLETED" &&
    (startDueIn === undefined || startDueIn === null)
  ) {
    return true;
  } if (
    status === "COMPLETED" &&
    isApprovalCertStatus // Only coming from server when status is pending or rejected
  ) {
    return true;
  } else if (status === "COMPLETED" && startDueIn) {
    let dueDate = moment(
      removeTimeFromServerDateString(startDueIn),
      "YYYY-MM-DD"
    );
    let isDueDateBig = moment().utc().isBefore(dueDate);
    return isDueDateBig;
  }
  return false;
};

export const getCourseAssignedList = (searchText, isMandatory, callback) => {
  const params = {
    search: searchText,
    isMandatory: isMandatory,
  };
  apiService
    .postCall(APIServicePath.courseAssignedList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getOtherCourseList = (params, callback) => {
  apiService
    .postCall(APIServicePath.courseOtherList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchChapterMarkAsRead = (params, callback) => {
  apiService
    .postCall(APIServicePath.chapterMarkAsRead, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchChapterMarkAsUnread = (params, callback) => {
  apiService
    .postCall(APIServicePath.chapterMarkAsUnread, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchAssessmentResultSave = (params, callback) => {
  apiService
    .postCall(APIServicePath.assessmentResultSave, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchAssesmentResultDetail = (params, callback) => {
  apiService
    .postCall(APIServicePath.AssesmentResultDetail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchEvaluationQuestionList = (callback) => {
  apiService
    .postCall(APIServicePath.evalutionQuestionList)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchEvaluationAnswer = (params, callback) => {
  apiService
    .postCall(APIServicePath.evalutionFormAnswer, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchEvaluationFormDetail = (params, callback) => {
  apiService
    .postCall(APIServicePath.evaluationFormDetail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
